html, body, #app {
  height: 100%;
  width: 100%;
}

body {
  background-color: #ffffff;
  color: #272727;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  margin: 0 auto;
  padding: 0;
}